import axios from "axios";
const axiosApi = () => axios.create({
  baseURL: 'https://enduserapi.narwhal.ir',
  headers: {
      'Accept': 'text/plain',
      'Content-Type': 'application/json',
      'Accept-Language' : 'fa-IR',
      'Authorization' : 'bearer ' + localStorage.getItem('token'),
  }
})

export default axiosApi;
